.header {
    /* background-color: black; */
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 50px;
    align-items: flex-start;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.header-start {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 10px;
}
.header-finish {
    position: absolute;
    right: 0;
    align-self: center;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 0.8em;
}

.header-timezone {
    color: white;
    font-size: 0.9em;
}
