.waypoint-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
}

.waypoint-item {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
}

.waypoint-card .card-number {
    font-size: 1em;
    padding-right: 0.2em;
}

.waypoint-bar-inactive {
    background: repeating-linear-gradient(0deg, #5c8229, #5c8229 1px, rgb(2, 78, 9) 1px, rgb(2, 78, 9) 2px);
}
