/* @tailwind base; */
@tailwind components;
@tailwind utilities;

html,
body {
    height: 100%;
    margin: 0px;

    font-family: 'Roboto', sans-serif;
}

.tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
}

.tooltip-measure {
    opacity: 1;
    font-weight: bold;
}

.tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
}

.tooltip-measure:before,
.tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: '';
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}

.tooltip-static:before {
    border-top-color: #ffcc33;
}

.control-action-button {
    font-size: 2em;
    cursor: pointer;
    z-index: 1000;
    padding-bottom: 0.3em;
}

.controlbar {
    padding: 0.2em;
    background-color: #0747a6;
    color: #deebff;
    font-size: 0.8em;
    text-align: center;
}

.controlbar i {
    padding: 0.3em;
}

.participant-card {
    border-style: solid;
    border-color: white;
    border-width: 1px;
    margin: 2px;
    padding: 5px;
    background-color: white;
    margin: 10px;
    font-size: 1.2em;

    box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.number-of-outtracks {
    float: right;
}

.participant-out {
    background-color: lightcoral;
}

.participant-in {
    background-color: lightgreen;
}

.participant-error {
    background-color: lightsalmon;
}

.form select,
input {
    margin: 2px;
}

.input-container {
    display: flex;
}

.input-container .select {
    flex: 1;
}

.ol-popup {
    position: absolute;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
}

.ol-popup-long {
    min-width: 280px;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.form .explanation {
    font-size: 0.9em;
    color: #222;
}

.form .example {
    font-size: 0.9em;
    background-color: #cccccc;
    margin: 2px;
}

.form .parameter-key {
    text-align: right;
    font-size: 13px;
}

/* Tooltip container */
.popup-tooltip {
    position: relative;
    display: inline-block;
    z-index: 9999;
}

/* Tooltip text */
.popup-tooltip .popup-tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    z-index: 9999;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.popup-tooltip .popup-tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.popup-tooltip:hover .popup-tooltiptext {
    visibility: visible;
    opacity: 1;
}

.results-progressbar-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

.error-message {
    background-color: #c62e2e;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    border-radius: 15px;
    font-weight: bold;
    position: relative;
}
