.summary-card:hover {
    background: #b4ec95;
}

.selected {
    background-color: #d4d4d4;
}

.selected:hover {
    background: #d4d4d4;
}

.list-of-unmatched-waypoints {
    text-align: right;
}

.item {
    margin: 2px;
    padding: 1px;
    border-radius: 2px;
    color: white;
    text-align: center;
}

.clickable {
    cursor: pointer;
}

.waypoint {
    display: inline-block;
    min-width: 2em;
}

.time-spent {
    display: inline-block;
    min-width: 2em;
    margin: 2px;
    padding: 1px;
    border-radius: 2px;
    background-color: #dddddd;
    text-align: center;
}

.ok-tag {
    background-color: green;
}

.error-tag {
    background-color: #f62525;
}

.minspeed-tag {
    background-color: #0080da;
}

.neutral-tag {
    background-color: lightskyblue;
}

.warning-tag {
    background-color: rgb(255, 120, 57);
}

.overlapping-tag {
    background-color: #a6761e;
}

.duration-tag {
    min-width: 4em;
}

.warning-icon {
    color: #ffae42;
    padding-left: 0.2em;
}

.group-of-stops-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
}

.group-of-stops {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}

.zone-name {
    display: inline-block;
}

.errors-card {
    padding: 0.5em;
    margin: 1rem;
    font-family: roboto;
    background: #bb0000;
    color: #ffffff;
    border-radius: 5px;
    position: relative;
    background: #f2e625;
    color: #3c3737;
    padding-right: 40px;
}

.errors-card-retry {
    position: absolute;
    bottom: 2px;
    right: 6px;
    padding: 2px;
}

.errors-card-retry img {
    width: 20px;
}

.under-mintimeinzone {
    color: #ff0000;
}

.div-collapse {
    text-align: left;
}
