.pasteCoords {
    cursor: pointer;
}
.rowClass{
    margin: 0;
    padding: 0;
    font-size: 12px;
}
.rowClass .ant-table-cell {
    font-weight: 700;
    padding: 0;
}
.ant-table-tbody{
    padding:3px !important;
  }
  .ant-table-tbody > tr > td{
    padding: 3px !important;
  }
