.action-buttons {
    top: 6.5em;
    left: 0.5em;
    position: absolute;
}

.action-buttons .ol-control {
    position: relative;
}

.action-button-selected svg{
    color: red;
}

.map-widget-loading-text-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
