#app {
    height: 100%;
}

.error {
    position: absolute;
    top: 50%;
    padding: 2em;
    font-family: Roboto;
    text-align: center;
}

.map-legend {
    position: absolute;
    left: 3rem;
    top: 4rem;
    width: 10rem;
    padding: 0.1em;
    border-radius: 3px;
    background-color: black;
    opacity: 0.9;
    font-family: roboto;
}

.hidden {
    display: none;
}

.error-message-x-button {
    position: absolute;
    top: 2px;
    right: 6px;
    background: #df1111;
    border: 1px solid #ffffff;
    color: #ffffff;
    padding: 2px;
    border-radius: 5px;
    font-size: 12px;
}

.error-message-x-button:hover {
    cursor: pointer;
}

.legend-card select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.legend-card select::-ms-expand {
    display: none;
}
.legend-card {
    max-width: 200px;
    position: relative;
    padding-left: 20px;
    padding-top: 40px;
}

.legend-card select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 7px 10px;
    height: 42px;
    outline: 0;
    border: 0;
    border-radius: 0;
    background: #f0f0f0;
    color: #7b7b7b;
    font-size: 1em;
    color: #999;
    font-family: 'Quicksand', sans-serif;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    position: relative;
    transition: all 0.25s ease;
}

.legend-card select:hover {
    background: #b1e8cd;
}

.grid-container {
    display: grid;
    grid-template-columns: 5.5fr 4.5fr;
    grid-template-rows: 1fr 18fr auto 8fr;
    overflow: hidden;
    height: 100vh;
}

.grid-container-with-speedinfo {
    grid-template-areas: 'header header' 'map data' 'speed-info data' 'chart data';
}

.grid-container-without-speedinfo {
    grid-template-areas: 'header header' 'map data' 'map data' 'map data';
}

.speed-info {
    text-align: center;
    padding: 0;
}

.header {
    grid-area: header;
}

.map {
    grid-area: map;
    position: relative;
}

.map-widget {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.chart {
    grid-area: chart;
    position: relative;
}

.speed-chart {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}

.controls {
    grid-area: controls;
}

.data {
    grid-area: data;
    height: 100%;
    overflow-y: auto;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
}

.form {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}
.chart {
    grid-area: chart;
}
