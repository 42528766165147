.track-card.summary-card {
    box-shadow: 0 0 0 0;
    margin: 0; 
    padding: 4px;
}

.track-card .card-number {
    font-size: 1.2em;
    padding-right: 0.2em;
}
