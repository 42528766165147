.ranges {
    height: 1.2em;
    position: relative;
    width: 100%;
}

.orderedRangeBar {
    display: flex;
    align-items: flex-end;
    flex: 1;
}
.ranges-theme-match-track {
    background-color: #5c8229;
}

.ranges-theme-opposite {
    background-color: #07a9ce;
}

.inner-bar-matchedGps {
    background-color: #005500;
}
.inner-bar-matched {
    background-color: #5c8229;
}
.inner-bar-finish {
    background-color: black;

}
.inner-bar-active {
    background-color: #e72512;
}

.inner-bar-inactive {
    background: repeating-linear-gradient(0deg, #5c8229, #5c8229 1px, rgb(2, 78, 9) 1px, rgb(2, 78, 9) 2px);
}

.inner-bar-end {
    background-color: gray;
    background: repeating-linear-gradient(45deg, #727272, #727272 2px, #323232 1px, #323232 3px);
}
.inner-bar-dns-dnf {
    background-color: #727272;
}
