.map-legend-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    padding: 0.1em;
}

.legend-bar {
    height: 0.4em;
    order: 0;
    flex: 1 1 auto;
    align-self: center;
}

.legend-data {
    color: white;
    order: 0;
    flex: 0 1 auto;
    align-self: center;
    padding-left: 0.2em;
    padding-right: 0.2em;
    width: 80%;
    font-size: 14px;
}